import React, { useState } from "react";
import {
  Document,
  Page,
  Text,
  Font,
  StyleSheet,
  View,
  Image,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
} from "@material-tailwind/react";

// Загрузка кастомного шрифта
Font.register({
  family: "CustomFont",
  src: "/fonts/Roboto-Regular.ttf", // Замените на фактический URL или путь к файлу шрифта
});

const ActOfCompletedDocument = ({ lead, hiddenSign }) => {
  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#fff",
      fontFamily: "CustomFont",
      fontSize: 7,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    header: {
      flexDirection: "row",
      margin: 10,
      padding: 10,
      justifyContent: "space-between",
    },
    logo: {
      width: 150,
      objectFit: "contain",
    },
    seal: {
      width: 200,
      height: 200,
      objectFit: "contain",
      position: "absolute",
      bottom: -75,
      left: "50%",
    },
    sign: {
      width: 200,
      height: 200,
      objectFit: "contain",
      position: "absolute",
      bottom: -78,
      left: "72%",
    },
    paragraph: {
      marginBottom: 5,
    },
    table: {
      flexDirection: "column",
      marginBottom: 20,
    },
    tableRow: {
      flexDirection: "row",
      border: "1px solid #000",
      borderBottom: 0,
    },
    footer: {
      margin: 10,
      marginTop: 0,
      padding: 10,
      flexGrow: 1,
    },
    title: {
      fontSize: 15,
      marginBottom: 50,
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image src={"/images/logo.png"} style={styles.logo} />
          <View style={{ flexBasis: 200, textAlign: "right" }}>
            <Text style={{ marginBottom: 5 }}>ООО “Гарант”</Text>
            <Text style={{ marginBottom: 5 }}>ИНН 4705097126</Text>
            <Text style={{ marginBottom: 5 }}>КПП 470501001</Text>
            <Text style={{ marginBottom: 5 }}>
              Адрес: г. Санкт-Петербург, ул. Фрунзе д.6
            </Text>
            <Text style={{ marginBottom: 5 }}>
              Телефон: 8 (812)–220 -70 -70
            </Text>
            <Text style={{ marginBottom: 5 }}>spbgarant.ru</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text
            style={{
              textAlign: "center",
              marginBottom: "20",
              fontWeight: 700,
              fontSize: 12,
            }}
          >
            Акт выполненных работ № {lead?.attributes?.order_number}
          </Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text
                style={{
                  flexBasis: 300,
                  padding: 3,
                  borderRight: "1px solid #000",
                }}
              >
                Аппарат:
              </Text>
              <Text style={{ flexBasis: 300, padding: 3 }}>
                {lead.attributes.correct_info.device}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text
                style={{
                  flexBasis: 300,
                  padding: 3,
                  borderRight: "1px solid #000",
                  borderBottom: "1px solid #000",
                }}
              >
                Производитель/модель:
              </Text>
              <Text
                style={{
                  flexBasis: 300,
                  padding: 3,
                  borderBottom: "1px solid #000",
                }}
              >
                {lead.attributes.correct_info.brand}/
                {lead.attributes.correct_info.model}
              </Text>
            </View>
          </View>
          <Text style={styles.title}>
            Выполненные работы: {lead.attributes?.correct_info.work_done}
          </Text>
          <Text style={styles.title}>
            Общая стоимость: {lead.attributes?.correct_info.estimation || 0} руб
          </Text>
          <Text style={styles.title}>
            Дата выдачи: {lead.attributes?.correct_info.date_issue}
          </Text>
        </View>
        <View style={styles.footer}>
          <Text style={{ marginBottom: 20 }}>
            С условиями сервисного обслуживание ознакомлен:
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <Text>Заказчик:____________________/____________</Text>
            {!hiddenSign && (
              <>
                <Image src={"/images/seal.png"} style={styles.seal} />
                <Image src={"/images/sign.png"} style={styles.sign} />
              </>
            )}
            <Text>Исполнитель:____________________/____________</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const ActOfCompleted = ({ lead, hiddenSign, isPreview }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <>
      {isPreview ? (
        <>
          <Button onClick={handleOpen}>Акт выполненных работ</Button>
          <Dialog
            open={open}
            size={"sm"}
            handler={handleOpen}
            className="w-full !max-w-[90%] lg:!max-w-[40%]"
          >
            <DialogHeader>{`Акт №${lead?.attributes.order_number}.pdf`}</DialogHeader>
            <DialogBody divider className="w-full">
              <PDFViewer width="100%" height="600">
                <ActOfCompletedDocument lead={lead} hiddenSign={hiddenSign} />
              </PDFViewer>
            </DialogBody>
          </Dialog>
        </>
      ) : (
        <Button className="!p-0 h-10">
          <PDFDownloadLink
            document={
              <ActOfCompletedDocument lead={lead} hiddenSign={hiddenSign} />
            }
            fileName={`Акт №${lead?.attributes.order_number}.pdf`}
            className="flex justify-center items-center h-full w-full"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Загрузка документа..." : "Акт выполненных работ"
            }
          </PDFDownloadLink>
        </Button>
      )}
    </>
  );
};

export default ActOfCompleted;
