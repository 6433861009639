import React from "react";
import Print from "./Print";
import SendMessage from "./SendMessage";
import { Button } from "@material-tailwind/react";
/* import { telegramSendMessage } from "telegram-message-send"; */

const Index = ({ client, userName, leadId, lead }) => {
  /*   const botKey = process.env.REACT_APP_TG_TOKEN;
  const chatId = "-1002123584039"; */

  /* const handleSendMessage = () => {
    telegramSendMessage(botKey, chatId, "Тестовое сообщение из CRM");
  }; */

  return (
    <div className="flex flex-wrap gap-3 mb-4">
      <Print lead={lead} />
      <SendMessage client={client} userName={userName} leadId={leadId} />
      <Button /* onClick={handleSendMessage} */>Написать в тг</Button>
    </div>
  );
};

export default Index;
