import axios from 'axios';
import jwt_decode from 'jwt-decode';

const apiUrl = process.env.REACT_APP_UPLOAD_URL;

export const strapiLogin = async (identifier, password) => {
    const response = await axios.post(`${apiUrl}/api/auth/local`, {
        "identifier": identifier,
        "password": password
    });
    return response.data;
};

export const strapiLogout = () => {
    localStorage.removeItem('token');
    window.location.reload();
}

export const getOrders = async ({ id }) => {
    const response = await axios.get(`${apiUrl}/api/orders`, {});
    return response.data;
}

export const setToken = (token) => {
    localStorage.setItem('token', token);
};

export const getToken = () => {
    return localStorage.getItem('token');
};

export const getUserFromToken = () => {
    const token = getToken();
    if (token) {
        const decodedToken = jwt_decode(token);
        return decodedToken;
    }
    return null;
};

export const getMasters = async (userToken) => {
    const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/users`, {
            headers: {
                Authorization: "Bearer " + userToken,
            },
        })
    return response;
}

export const sendToTelegram = async (message) => {
    const botToken = process.env.TG_TOKEN;
    const chatId = '-1002123584039';

    try {
        await axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
            chat_id: chatId,
            text: message || "Пустое сообщение",
        });

        console.log('Сообщение успешно отправлено!');
    } catch (error) {
        console.error('Ошибка при отправке сообщения:', error);
    }
}