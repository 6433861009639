import React from "react";

const CalculationsRow = ({ item }) => {
  console.log("item: ", item);
  const formattedDate = new Date(item?.date).toLocaleString("en-GB", {
    timeZone: "UTC",
  });

  return (
    <tr
      className={`rounded-t-md border border-gray-100 border-b-0 ${
        item.total_cost ? "bg-green-200" : "bg-red-200"
      }`}
    >
      <td className="pl-5 py-3">
        <p className="text-sm leading-none text-gray-800 ml-2">
          {formattedDate}
        </p>
      </td>
      <td className="pl-5 py-3">
        <p className="text-sm leading-none text-gray-800 ml-2">
          {item?.order_number}
        </p>
      </td>
      <td className="pl-5 py-3">
        <p className="text-sm leading-none text-gray-800 ml-2">
          {item?.master_cost || item.amount} руб.
        </p>
      </td>
      <td className="pl-5 py-3">
        <p className="text-sm leading-none text-gray-800 ml-2">
          {item?.author || item.user}
        </p>
      </td>
      <td className="pl-5 py-3">
        <p className="text-sm leading-none text-gray-800 ml-2">
          {item?.comment}
        </p>
      </td>
    </tr>
  );
};

export default CalculationsRow;
