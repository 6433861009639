import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Input, Switch } from "@material-tailwind/react";
import axios from "axios";
import { toast } from "react-hot-toast";

import { getToken } from "../../../helpers";

const Accounting = ({ id, data, isAdmin, masterLead, userName }) => {
  const userToken = getToken();
  const [calculations, setCalculations] = useState({});
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setCalculations(data?.attributes.income);
  }, [data]);

  const percent = useRef(0);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newFormData = []; // создаем копию массива данных формы
    const formElements = event.target.elements; // получаем элементы формы
    const formDataObject = {}; // создаем объект для хранения данных формы

    // перебираем элементы формы и добавляем их значения в объект formDataObject
    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i];
      if (element.name) {
        formDataObject[element.name] = element.value;
      }
    }

    newFormData.push(formDataObject); // добавляем объект с данными формы в массив

    if (percent.current.checked === true) {
      const orderProfit =
        newFormData[0].total_cost -
        newFormData[0].total_cost * (newFormData[0].master_cost / 100) -
        newFormData[0].total_cost * (newFormData[0].supervisor_cost / 100) -
        newFormData[0].total_cost * (newFormData[0].manager_cost / 100);

      // Обновляем инфомрацию в лиде
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/orders/${id}?populate=*`,
          {
            data: {
              order_profit: +orderProfit,
              income: {
                total_cost: newFormData[0].total_cost,
                master_cost:
                  newFormData[0].total_cost *
                  (newFormData[0].master_cost / 100),
                supervisor_cost:
                  newFormData[0].total_cost *
                  (newFormData[0].supervisor_cost / 100),
                manager_cost:
                  newFormData[0].total_cost *
                  (newFormData[0].manager_cost / 100),
                master_fio: masterLead,
                date: new Date(),
                author: userName,
                comment: `Выплата за наряд №${data?.attributes.order_number}`,
                order_number: data?.attributes.order_number,
              },
            },
          },
          {
            headers: {
              Authorization: "Bearer " + userToken,
            },
          }
        )
        .then((response) => {
          setCalculations(response.data?.data?.attributes.income);
          toast.success("Информация обновлена");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const orderProfit =
        newFormData[0].total_cost -
        newFormData[0].master_cost -
        newFormData[0].supervisor_cost -
        newFormData[0].manager_cost;

      // Обновляем инфомрацию в лиде
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/orders/${id}?populate=income`,
          {
            data: {
              order_profit: +orderProfit,
              income: {
                total_cost: newFormData[0].total_cost,
                master_cost: newFormData[0].master_cost,
                supervisor_cost: newFormData[0].supervisor_cost,
                manager_cost: newFormData[0].manager_cost,
                master_fio: masterLead,
                date: new Date(),
                author: userName,
              },
            },
          },
          {
            headers: {
              Authorization: "Bearer " + userToken,
            },
          }
        )
        .then((response) => {
          setCalculations(response.data?.data?.attributes.income);
          toast.success("Информация обновлена");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      {isAdmin && data?.attributes.order_status === "Выдан" ? (
        <div className="mt-8">
          <form
            className="grid grid-cols-6 gap-4 mb-12"
            onSubmit={(event) => handleSubmit(event)}
          >
            <div className="col-span-2">
              <Input
                label="Общая сумма"
                name="total_cost"
                value={formValues.total_cost}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-2">
              <Input
                label="Процент мастера"
                name="master_cost"
                value={formValues.master_cost}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-2">
              <Input
                label="Процент куратора"
                name="supervisor_cost"
                value={formValues.supervisor_cost}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-2">
              <Input
                label="Процент менеджера"
                name="manager_cost"
                value={formValues.manager_cost}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-2">
              <Switch label="Ввод процентов" inputRef={percent} />
            </div>
            <Button className="block col-span-6" type="submit">
              Сохранить
            </Button>
          </form>
          <div className="bg-white mb-6 col-span-6">
            <h3 className="mb-4 text-xl font-bold">История расчетов:</h3>
            <div className="rounded-lg border">
              {calculations && (
                <table className="min-w-full text-left text-sm font-light">
                  <thead className="border-b font-medium dark:border-neutral-500">
                    <tr className="rounded-t-md border border-gray-100 border-b-0">
                      <td className="pl-5 py-3">
                        <p className="text-sm leading-none text-gray-600">
                          Дата
                        </p>
                      </td>
                      <td className="pl-5 py-3">
                        <p className="text-sm leading-none text-gray-600">
                          Общая стоимость
                        </p>
                      </td>
                      <td className="pl-5 py-3">
                        <p className="text-sm leading-none text-gray-600">
                          Сумма мастера
                        </p>
                      </td>
                      <td className="pl-5 py-3">
                        <p className="text-sm leading-none text-gray-600">
                          Сумма куратора
                        </p>
                      </td>
                      <td className="pl-5 py-3">
                        <p className="text-sm leading-none text-gray-600">
                          Сумма менеджера
                        </p>
                      </td>
                      <td className="pl-5 py-3">
                        <p className="text-sm leading-none text-gray-600">
                          Автор расчета
                        </p>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                      <td className="whitespace-nowrap pl-5 py-4 font-medium">
                        {calculations.date || ""}
                      </td>
                      <td className="whitespace-nowrap pl-5 py-4">
                        {calculations.total_cost || ""}
                      </td>
                      <td className="whitespace-nowrap pl-5 py-4">
                        {calculations.master_cost || ""}
                      </td>
                      <td className="whitespace-nowrap pl-5 py-4">
                        {calculations.supervisor_cost || ""}
                      </td>
                      <td className="whitespace-nowrap pl-5 py-4">
                        {calculations.manager_cost || ""}
                      </td>
                      <td className="whitespace-nowrap pl-5 py-4">
                        {calculations.author || ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      ) : (
        <p className="mt-12 col-span-6 text-xl">
          Для введения расчетов, переведите наряд в статус "Выдан"
        </p>
      )}
    </>
  );
};

export default Accounting;
