import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { getToken } from "../../../helpers";
import { Button, Radio } from "@material-tailwind/react";
import toast from "react-hot-toast";

const Master = ({ isAdmin, id, data }) => {
  const userToken = getToken();
  const [allMasters, setAllMasters] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/users`, {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      })
      .then((response) => {
        setAllMasters(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userToken]);

  const setMaster = (e) => {
    e.preventDefault();
    let enterId = data?.attributtes?.users_permissions_user?.data?.id;
    e.target.querySelectorAll("input[name=master]").forEach((item) => {
      if (item.checked) {
        enterId = item.dataset.id;
      }
    });

    if (enterId !== null) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/orders/${id}`,
          {
            data: {
              users_permissions_user: {
                id: enterId,
              },
            },
          },
          {
            headers: {
              Authorization: "Bearer " + userToken,
            },
          }
        )
        .then((response) => {
         toast.success('Мастер назначен!')
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.error('Выберите мастера!')
    }
  };

  return (
    <>
      <div className="mt-8">
        <div className="bg-white mb-6">
          <h3 className="mb-4 text-xl font-bold">Назначить ответственного</h3>
          {isAdmin ? (
            <form onSubmit={setMaster}>
              <div className="mb-4 grid grid-cols-4 gap-3">
                {allMasters?.map((item) => (
                  <Radio
                    key={item.id}
                    id={item.id}
                    data-id={item.id}
                    name="master"
                    label={`${item.name} ${item.last_name}`}
                    value={`${item.name} ${item.last_name}`}
                    defaultChecked={
                      item.id ===
                      data?.attributes?.users_permissions_user?.data?.id
                        ? true
                        : false
                    }
                  />
                ))}
              </div>
              <Button type="submit" className="mt-4">
                Сохранить
              </Button>
            </form>
          ) : (
            "У вас нет доступа к этому разделу"
          )}
        </div>
      </div>
    </>
  );
};

export default Master;
