import React from "react";
import { Link } from "react-router-dom";

const LeadItemRow = ({ item, isAdmin }) => {
  const id = item.id;
  const nowDate = new Date();
  const deadlineDate = item.attributes.correct_info?.deadline
    ? new Date(item.attributes.correct_info?.deadline)
    : null;

  const getStatusColor = (status) => {
    switch (status) {
      case "Новая":
        return "bg-blue-100 hover:bg-blue-200";
      case "Согласовать":
        return "bg-gray-100 hover:bg-gray-200";
      case "Отправить инженера":
        return "bg-green-100 hover:bg-green-200";
      case "Проверить":
        return "bg-orange-100 hover:bg-orange-200";
      case "Архив":
        return "bg-gray-200 hover:bg-gray-300";
      case "Отказ":
        return "bg-red-200 hover:bg-red-300";
      default:
        return "bg-white";
    }
  };

  return (
    <>
      <tr
        className={`flex flex-col gap-2 md:table-row p-3 md:p-0 md:h-24 max-xs:h-auto border border-gray-100 ${getStatusColor(
          item.attributes.order_status
        )} ${deadlineDate && nowDate >= deadlineDate && "animate-blink"}`}
      >
        <td>
          <Link
            to={`/lead/${id}`}
            className="flex justify-center md:justify-start items-center pl-5"
          >
            <p className="text-base font-medium leading-none text-gray-700 mr-2 text-center md:text-left">
              {item.attributes.order_number}
            </p>
          </Link>
        </td> 
        <td className="md:pl-5">
          <div className="flex items-center">
            <p className="text-sm leading-none text-gray-600 py-3 px-5 bg-gray-100 rounded focus:outline-none">
              {item.attributes.order_status}
            </p>
          </div>
        </td>
        <td className="md:pl-5 hidden">
          <div className="flex items-center">
            <p className="text-sm leading-none text-gray-600 md:ml-2">
              {item.attributes.correct_info?.kind_of_repair}
            </p>
          </div>
        </td>
        <td className="md:pl-5">
          <div className="flex items-center">
            <p className="text-sm leading-none text-gray-600 md:ml-2">
              <span className="font-bold md:hidden">Дата заказа:</span>{" "}
              {`${item.attributes.correct_info?.sold_date || "не заполнено"} ${
                item.attributes.correct_info?.sold_time
                  ? item.attributes.correct_info?.sold_time
                  : ""
              }`}
            </p>
          </div>
        </td>
        <td className="md:pl-5">
          <div className="flex flex-col">
            {item.attributes.correct_info?.device && (
              <p className="text-sm leading-none text-gray-600 md:ml-2 mb-2 truncate ... w-[200px]">
                {item.attributes.correct_info.device}
              </p>
            )}

            {item.attributes.correct_info?.brand && (
              <p className="text-sm leading-none text-gray-600 md:ml-2 mb-2">
                {item.attributes.correct_info.brand}
              </p>
            )}
            {item.attributes.correct_info?.model_code && (
              <p className="text-sm leading-none text-gray-600 md:ml-2 mb-2">
                {item.attributes.correct_info.model_code}
              </p>
            )}
          </div>
        </td>
        <td className="md:pl-5">
          <div className="flex items-center">
            <p className="text-sm leading-none text-gray-600 md:ml-2">
              <span className="font-bold md:hidden">Мастер:</span>
              {
                item.attributes?.users_permissions_user.data?.attributes?.name
              }{" "}
              {
                item.attributes?.users_permissions_user.data?.attributes
                  ?.last_name
              }
            </p>
          </div>
        </td>
        <td className="md:pl-5">
          <div className="grid gap-2">
            <p className="text-sm leading-none text-gray-600 md:ml-2">
              <span className="font-bold md:hidden">Телефон:</span>
              <Link
                to={`tel:${item.attributes.client?.data?.attributes.phone}`}
              >
                {item.attributes.client?.data?.attributes.phone}
              </Link>
            </p>
            <p className="text-sm leading-none text-gray-600 md:ml-2">
              <strong className="max-w-[150px] whitespace-normal block">
                <span className="font-bold md:hidden">Адрес выезда:</span>{" "}
                {item.attributes.client?.data?.attributes.address || "Не задан"}
              </strong>
            </p>
          </div>
        </td>
        {isAdmin && (
          <td className="md:pl-5">
            <div className="grid gap-3">
              <p className="text-sm leading-none text-gray-600 md:ml-2 whitespace-break-spaces">
                Предоплата:{" "}
                <strong>
                  {item.attributes.correct_info.prepayment || 0} руб.
                </strong>
              </p>
              <p className="text-sm leading-none text-gray-600 md:ml-2">
                Стоимость работ:{" "}
                <strong>
                  {item.attributes.correct_info.estimation || 0} руб.
                </strong>
              </p>
            </div>
          </td>
        )}
        <td className="md:pl-4">
          <Link to={`/lead/${id}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="hidden md:block w-6 h-6 mx-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <span className="block lg:hidden mx-auto mt-3 border p-2 text-center">
              Перейти к заявке
            </span>
          </Link>
        </td>
      </tr>
      <tr className="h-3" />
    </>
  );
};

export default LeadItemRow;
