import React from "react";
import { Link } from "react-router-dom";

const MastersItemRow = ({ item }) => {
  console.log("item: ", item);
  return (
    <tr className="h-24 border border-gray-100 ">
      <td className="pl-5 max-w-xs whitespace-break-spaces">
        <Link to={`/masters/${item.id}`}>{`${item.last_name} ${item.name} ${
          item.middle_name || ""
        }`}</Link>
      </td>
      <td className="pl-5">{item.phone || "Не заполненено"}</td>
      <td className="pl-5">{item.email}</td>
      <td className="pl-5">
        <Link to={`/masters/${item.id}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mx-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </Link>
      </td>
    </tr>
  );
};

export default MastersItemRow;
