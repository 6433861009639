import { Alert } from "@material-tailwind/react";
import React from "react";

const AlertItem = ({ msgId, text, author, date, onClick }) => {
  const formattedDate = new Date(date).toLocaleString("en-GB", {
    timeZone: "UTC",
  });
  return (
    <>
      <Alert className="mb-4 flex justify-between group relative">
        <div className="mb-2">{formattedDate}</div>
        <div className="w-full">{text}</div>
        <div className="mt-4 p-2 bg-gray-50/20 w-fit rounded font-meduim">
          {author}
        </div>
        <svg
          onClick={onClick}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="blue"
          className="w-8 h-8 p-2 absolute top-1 right-1 opacity-0 rounded-full bg-cyan-50 group-hover:opacity-100 cursor-pointer transition-all"
          data-msg-id={msgId}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
          />
        </svg>
      </Alert>
    </>
  );
};

export default AlertItem;
