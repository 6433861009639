import React from "react";
import { Button } from "@material-tailwind/react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  pdf,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";

// Load the font
import Roboto from "../../fonts/Roboto-Regular.ttf";

Font.register({
  family: "Roboto",
  src: Roboto,
});

const styles = StyleSheet.create({
  page: {
    padding: 10,
    fontFamily: "Roboto",
    position: "relative",
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
  },
  chat: {
    marginBottom: 10,
    padding: 5,
    borderWidth: 1,
    borderColor: "#000",
  },
  separator: {
    marginVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#000",
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
  },
});

const ReportDocument = ({ leads, pageTitle }) => (
  <Document>
    <Page style={styles.page}>
      <Text style={styles.title}>{pageTitle}</Text>
      {leads.map((item, index) => (
        <View key={index} wrap={false}>
          <Text style={styles.text}>{item.attributes.order_number}</Text>
          {(pageTitle === "Новые заявки" ||
            pageTitle === "Отправить инженера") && (
            <Text style={styles.text}>
              Дата выезда:{" "}
              {item.attributes.correct_info?.sold_date || "не заполнено"}
            </Text>
          )}
          <Text style={styles.text}>
            Тип техники: {item.attributes.correct_info.device}{" "}
            {item.attributes.correct_info.brand}{" "}
            {item.attributes.correct_info.model_code}
          </Text>
          <Text style={styles.text}>
            Дефект: {item.attributes.correct_info.defect}
          </Text>
          <Text style={styles.text}>
            Номер клиента: {item.attributes.client?.data?.attributes.phone}
          </Text>
          <Text style={styles.text}>
            Адрес клиента:{" "}
            {item.attributes.client?.data?.attributes.address || "Не задан"}
          </Text>
          {pageTitle === "Согласование" && (
            <View>
              <Text style={styles.text}>Чат:</Text>
              {item.attributes.chat.map((chatItem, chatIndex) => (
                <View key={chatIndex} style={styles.chat}>
                  <Text style={styles.text}>Сообщение: {chatItem.message}</Text>
                </View>
              ))}
            </View>
          )}
          {pageTitle !== "Согласовать" && (
            <Text style={styles.text}>
              ФИО мастера:{" "}
              {item.attributes?.users_permissions_user.data?.attributes?.name}{" "}
              {
                item.attributes?.users_permissions_user.data?.attributes
                  ?.last_name
              }
            </Text>
          )}
          {pageTitle === "Отказные" && (
            <Text style={styles.text}>
              Причина отказа: {item.attributes.alt_code}
            </Text>
          )}
          {index !== leads.length - 1 && <View style={styles.separator} />}
        </View>
      ))}
      <Text
        style={styles.footer}
        render={({ pageNumber, totalPages }) =>
          `Страница ${pageNumber} из ${totalPages}`
        }
        fixed
      />
    </Page>
  </Document>
);

const DownloadReport = ({ leads, pageTitle }) => {
  const sortedData = leads.sort((a, b) => {
    const dateA = new Date(a.attributes.correct_info.sold_date);
    const dateB = new Date(b.attributes.correct_info.sold_date);
    return dateB - dateA;
  });

  const now = new Date();
  const formatDate = (date) => {
    const pad = (n) => (n < 10 ? "0" + n : n);
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1);
    const year = date.getFullYear().toString().slice(-2);
    return `${day}.${month}.${year}`;
  };

  const nowDate = formatDate(now);

  const handleDownload = async () => {
    const blob = await pdf(
      <ReportDocument leads={sortedData} pageTitle={pageTitle} />
    ).toBlob();
    saveAs(blob, `${pageTitle}-${nowDate}.pdf`);
  };

  return <Button onClick={handleDownload}>Скачать отчет в PDF</Button>;
};

export default DownloadReport;
