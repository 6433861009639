import React from "react";
import { Link } from "react-router-dom";

const TableRow = ({ filename, size, link, index, onClick, id }) => {
  return (
    <>
      <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
        <td className="whitespace-nowrap px-6 py-4 font-medium">{index + 1}</td>
        <td className="whitespace-nowrap px-6 py-4">{filename}</td>
        <td className="whitespace-nowrap px-6 py-4">{size} кб</td>
        <td className="whitespace-nowrap px-6 py-4">
          <Link to={link} download>
            Скачать
          </Link>
        </td>
        <td className="whitespace-nowrap px-6 py-4">
          <button type="button" onClick={onClick} data-upload-id={id}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
              data-upload-id={id}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </td>
      </tr>
    </>
  );
};

export default TableRow;
