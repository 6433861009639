import axios from "axios";
import React, { useEffect, useState } from "react";
import { getToken } from "../../../helpers";
import { Alert, Button } from "@material-tailwind/react";
import { toast } from "react-hot-toast";

const UploadPhoto = ({ id, data, filesList }) => {
  const userToken = getToken();
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFiles(filesList);
  }, [filesList]);

  let currentUploads = [];

  data?.attributes.device_photos.data?.forEach((item) => {
    currentUploads.push(item.id);
  });

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("files", file);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      );
      currentUploads.push(res.data[0].id);
      const responseFiles = await axios.put(
        `${process.env.REACT_APP_API_URL}/orders/${id}?populate=device_photos`,
        {
          data: {
            device_photos: currentUploads,
          },
        },
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      );

      setFiles(responseFiles.data?.data?.attributes.device_photos.data);
      toast.success("Файлы успешно загружены");
    } catch (error) {
      toast.error("Ошибка при загрузке файлов");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = (itemId) => {
    const updatedPhotoArray = files.filter((item) => +item.id !== itemId);

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/orders/${id}?populate=device_photos`,
        {
          data: {
            device_photos: updatedPhotoArray,
          },
        },
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      )
      .then((response) => {
        setFiles(response.data?.data?.attributes.device_photos?.data);
        toast.success("Файл удален");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Ошибка удаления файла");
      });
  };

  return (
    <div className="mb-4 p-4 border rounded">
      <h3 className="mb-4 text-xl font-bold">Загрузка фотографий техники</h3>
      <form onSubmit={handleSubmit} className="mb-5">
        <div className="mb-5">
          <input
            type="file"
            onChange={handleFileUpload}
            label="Загрузите 1 файл"
            accept="image/*"
          />
        </div>
        <Button type="submit">
          {loading ? "Загрузка…" : "Загрузить файл"}
        </Button>
      </form>
      <div>
        <h3 className="mb-4 text-xl font-bold">Галерея фото</h3>

        {files ? (
          <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
            {files.map((item, index) => (
              <div
                key={item.id}
                id={item.id}
                index={index}
                className="relative group"
              >
                <img
                  src={process.env.REACT_APP_UPLOAD_URL + item.attributes.url}
                  alt=""
                />
                <button
                  type="button"
                  className="absolute top-1 right-1 opacity-0 group-hover:opacity-100"
                  onClick={() => onDelete(item.id)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <Alert color="blue" className="w-full">
              Пока нет загруженных фото
            </Alert>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadPhoto;
