import React from "react";
import CalculationsRow from "./CalculationsRow";
import Debiting from "./Debiting";

const Calculations = ({
  moneyRows,
  consumptionRows,
  master,
  isAdmin,
  userToken,
}) => {
  /* const [combinedRows, setCombinedRows] = useState([]); */
  // Объединение массивов
  const combinedRows = moneyRows.concat(consumptionRows);

  // Сортировка по ключу date в порядке убывания
  combinedRows.sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <>
      <div className="mx-auto mb-5 basis-3/4 overscroll-contain max-h-screen">
        {isAdmin && <Debiting master={master} userToken={userToken} />}
        <div className="w-full h-full rounded ">
          <div className="w-full">
            <div className="bg-white">
              <div className="mt-7 overflow-x-auto">
                <table className="w-full whitespace-nowrap">
                  <thead>
                    <tr className="rounded-t-md border border-gray-100 border-b-0">
                      <td className="pl-5 py-3">
                        <p className="text-sm leading-none text-gray-600 ml-2">
                          Дата
                        </p>
                      </td>
                      <td className="pl-5 py-3">
                        <p className="text-sm leading-none text-gray-600 ml-2">
                          Номер наряда
                        </p>
                      </td>
                      <td className="pl-5 py-3">
                        <p className="text-sm leading-none text-gray-600 ml-2">
                          Сумма
                        </p>
                      </td>
                      <td className="pl-5 py-3">
                        <p className="text-sm leading-none text-gray-600 ml-2">
                          Сотрудник
                        </p>
                      </td>
                      <td className="pl-5 py-3">
                        <p className="text-sm leading-none text-gray-600 ml-2">
                          Комментарий
                        </p>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {combinedRows?.map((item) => (
                      <CalculationsRow key={item?.id} item={item} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calculations;
