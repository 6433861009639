import React from "react";
import LeadItemRow from "../LeadItemRow";

const MasterLeads = ({ leads, isAdmin }) => {
  return (
    <>
      <div className="mx-auto mb-5 basis-3/4 overscroll-contain max-h-screen">
        <div className="w-full h-full rounded ">
          <div className="w-full">
            <div className="bg-white">
              <div className="mt-7 overflow-x-auto">
                <table className="w-full whitespace-nowrap">
                  <thead>
                    <tr className="rounded-t-md border border-gray-100 border-b-0">
                      <td className="pl-5 py-3">
                        <p className="text-sm leading-none text-gray-600 ml-2">
                          Номер наряда
                        </p>
                      </td>
                      <td className="pl-5 py-3">
                        <p className="text-sm leading-none text-gray-600 ml-2">
                          Дата выезда
                        </p>
                      </td>
                      <td className="pl-5 py-3">
                        <p className="text-sm leading-none text-gray-600 ml-2">
                          Об устройстве
                        </p>
                      </td>
                      <td className="pl-5 py-3">
                        <p className="text-sm leading-none text-gray-600 ml-2">
                          Мастер
                        </p>
                      </td>
                      <td className="pl-5 py-3">
                        <p className="text-sm leading-none text-gray-600 ml-2">
                          Данные клиента
                        </p>
                      </td>
                      {isAdmin && (
                        <td className="pl-5 py-3">
                          <p className="text-sm leading-none text-gray-600 ml-2">
                            Оплата
                          </p>
                        </td>
                      )}
                      <td className="pl-5 py-3"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {leads?.map((item) => (
                      <LeadItemRow
                        key={item.id}
                        id={item.id}
                        item={item}
                        isAdmin={isAdmin}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MasterLeads;
