import React from "react";
import UploadPhoto from "./UploadPhoto";
import UploadDocuments from "./UploadDocuments";

const Attachments = ({ id, data, isAdmin }) => {
  return (
    <div className="mt-8">
      <div className="bg-white mb-6">
        <UploadDocuments
          id={id}
          data={data}
          filesList={data?.attributes.order_files.data}
          isAdmin={isAdmin}
        />
        <UploadPhoto
          id={id}
          data={data}
          filesList={data?.attributes.device_photos.data}
          isAdmin={isAdmin}
        />
      </div>
    </div>
  );
};

export default Attachments;
