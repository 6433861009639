import {
  Option,
  Select,
  Input,
  Textarea,
  Button,
  DialogBody,
  DialogHeader,
  Dialog,
  Alert,
} from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import UseFetch from "../../../hooks/useFetch";
import axios from "axios";
import { getToken } from "../../../helpers";
import toast from "react-hot-toast";

const CorrectInfo = ({ id, isAdmin }) => {
  const nowDate = new Date();
  const setNowDate = `${nowDate.getFullYear()}-${
    nowDate.getMonth() + 1 < 10
      ? "0" + (nowDate.getMonth() + 1)
      : nowDate.getMonth() + 1
  }-${nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate()}`;

  const { data, loading, error } = UseFetch(`/orders/${id}?populate=*`);
  console.log("data: ", data?.attributes.correct_info.alt_code);

  const [openModal, setOpenModal] = useState(false);
  const [valueStatus, setValueStatus] = useState(data?.attributes.order_status);
  const [value2, setValue2] = useState(false);
  const [value3, setValue3] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [reason, setReason] = useState("");
  const [reasonAlert, setReasonAlert] = useState(false);
  const submitButtonRef = useRef(null);

  useEffect(() => {
    if (data) {
      setReason(data.attributes.correct_info.alt_code || "");
    }
  }, [data]);

  const handleReasonClick = () => {
    if (reason !== "") {
      setReasonAlert(false);
      handleOpen(false);
      submitButtonRef.current.click();
    } else {
      setReasonAlert(true);
    }
  };

  const handleSubmit = (event) => {
    const userToken = getToken();
    event.preventDefault();
    const newFormData = []; // создаем копию массива данных формы
    const formElements = event.target.elements; // получаем элементы формы
    const formDataObject = {}; // создаем объект для хранения данных формы

    // перебираем элементы формы и добавляем их значения в объект formDataObject
    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i];
      if (element.name) {
        formDataObject[element.name] = element.value;
      }
    }

    newFormData.push(formDataObject); // добавляем объект с данными формы в массив
    console.log("newFormData: ", newFormData);

    if (
      newFormData[0].order_status === "Отказ" &&
      newFormData[0].alt_code === ""
    ) {
      handleOpen(true);
      return false;
    }

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/orders/${id}?populate=correct_info,users_permissions_user,income,consumption`,
        {
          data: {
            id: id,
            order_status: valueStatus,
            correct_info: newFormData[0],
          },
        },
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      )
      .then((response) => {
        toast.success("Информация обновлена");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    setValueStatus(e);
  };
  const handleChange2 = (e) => {
    setValue2(e);
  };
  const handleChange3 = (e) => {
    setValue3(e);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOpen = () => setOpenModal(!openModal);

  return (
    <>
      {error ? (
        error
      ) : loading ? (
        "Loading…"
      ) : (
        <div className="mt-8">
          <div className="bg-white mb-6">
            <h3 className="mb-4 text-xl font-bold">
              Редактирование информации о наряде
            </h3>
            <form onSubmit={(event) => handleSubmit(event)}>
              <Dialog open={openModal} size={"sm"} handler={handleOpen}>
                <DialogHeader>Причина отказа</DialogHeader>
                <DialogBody divider>
                  <div className="mt-8 mb-2 w-full max-w-screen-lg">
                    <div className="mb-4 flex flex-col gap-6">
                      <Textarea
                        size="lg"
                        label="Причина"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </div>
                    {reasonAlert && (
                      <Alert color="red">Заполните причину ошибки</Alert>
                    )}
                    <Button
                      className="mt-6"
                      fullWidth
                      type="button"
                      onClick={handleReasonClick}
                    >
                      Сохранить
                    </Button>
                  </div>
                </DialogBody>
              </Dialog>

              <div className="grid grid-cols-1 gap-3 md:grid-cols-6">
                <div className="col-span-6 sm:col-span-3">
                  <div className="w-full mb-4">
                    <Select
                      label="Статус заказа"
                      value={valueStatus || data?.attributes.order_status}
                      onChange={handleChange}
                    >
                      <Option value="Новая">Новая</Option>
                      <Option value="Согласовать">Согласовать</Option>
                      <Option value="Отправить инженера">
                        Отправить инженера
                      </Option>
                      <Option value="Проверить">Проверить</Option>
                      <Option value="Выдан">Выдан</Option>
                      <Option value="Отказ">Отказ</Option>
                    </Select>
                  </div>
                  <div className="w-full mb-4">
                    <Select
                      required
                      label="Тип ремонта"
                      value={
                        value2 || data?.attributes.correct_info.type_of_repair
                      }
                      onChange={handleChange2}
                    >
                      <Option value="Платный">Платный</Option>
                      <Option value="Гарантийный">Гарантийный</Option>
                      <Option value="Гарантия СЦ">Гарантия СЦ</Option>
                      <Option value="На продажу">На продажу</Option>
                    </Select>
                  </div>
                  <div className="w-full mb-4">
                    <Select
                      name="kind_of_repair" 
                      label="Вид ремонта"
                      value={
                        value3 || data?.attributes.correct_info.kind_of_repair
                      }
                      onChange={handleChange3}
                    >
                      <Option value="Выездной">Выездной</Option>
                      <Option value="Стационарный">Стационарный</Option>
                    </Select>
                  </div>
                  <div className="absolute">
                    <Input
                      name="order_status"
                      className="h-0"
                      variant="standard"
                      type={"hidden"}
                      value={valueStatus || data?.attributes.order_status}
                    />
                    <Input
                      name="type_of_repair"
                      className="h-0"
                      variant="standard"
                      type={"hidden"}
                      value={
                        value2 || data?.attributes.correct_info.type_of_repair
                      }
                    />
                    <Input
                      name="kind_of_repair"
                      className="h-0"
                      variant="standard"
                      type={"hidden"}
                      value={
                        value3 || data?.attributes.correct_info.kind_of_repair
                      }
                    />
                  </div>
                  <div className="w-full mb-4">
                    <Input
                      name="device"
                      value={
                        formValues.device === ""
                          ? ""
                          : formValues.device ||
                            data?.attributes.correct_info.device
                      }
                      onChange={handleInputChange}
                      label="Тип устройства"
                      required
                    />
                  </div>
                  <div className="w-full mb-4">
                    <Input
                      name="brand"
                      value={
                        formValues.brand === ""
                          ? ""
                          : formValues.brand ||
                            data?.attributes.correct_info.brand
                      }
                      onChange={handleInputChange}
                      label="Производитель"
                      required
                    />
                  </div>
                  <div className="w-full mb-4">
                    <Input
                      name="model"
                      value={
                        formValues.model === ""
                          ? ""
                          : formValues.model ||
                            data?.attributes.correct_info.model
                      }
                      onChange={handleInputChange}
                      label="Модель"
                      required
                    />
                  </div>
                  <div className="w-full mb-4">
                    <Input
                      name="model_code"
                      value={
                        formValues.model_code === ""
                          ? ""
                          : formValues.model_code ||
                            data?.attributes.correct_info.model_code
                      }
                      onChange={handleInputChange}
                      label="Код модели"
                    />
                  </div>
                  <div className="w-full mb-4">
                    <Input
                      name="serial_number"
                      value={
                        formValues.serial_number === ""
                          ? ""
                          : formValues.serial_number ||
                            data?.attributes.correct_info.serial_number
                      }
                      onChange={handleInputChange}
                      label="Сер. номер"
                      required
                    />
                  </div>
                  <div className="w-full mb-4">
                    <Input
                      name="alt_code"
                      value={
                        reason !== undefined
                          ? reason
                          : data?.attributes.correct_info.alt_code ?? ""
                      }
                      onChange={(e) => setReason(e.target.value)}
                      label="Причина отказа"
                    />
                  </div>
                  <div className="w-full mb-4">
                    <Input
                      name="defect"
                      value={
                        formValues.defect === ""
                          ? ""
                          : formValues.defect ||
                            data?.attributes.correct_info.defect
                      }
                      onChange={handleInputChange}
                      label="Неисправность"
                    />
                  </div>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <div className="w-full mb-4">
                    <Input
                      name="defect_tag"
                      value={
                        formValues.defect_tag === ""
                          ? ""
                          : formValues.defect_tag ||
                            data?.attributes.correct_info.defect_tag
                      }
                      onChange={handleInputChange}
                      label="Гарантия(напр. 6 месяцев)"
                      required
                    />
                  </div>
                  <div className="w-full mb-4 flex flex-col gap-4 lg:flex-row lg:gap-1">
                    <Input
                      name="sold_date"
                      value={
                        formValues.sold_date === ""
                          ? ""
                          : formValues.sold_date ||
                            data?.attributes.correct_info.sold_date ||
                            setNowDate
                      }
                      onChange={handleInputChange}
                      type={"date"}
                      label="Дата выезда"
                    />
                    <Input
                      name="sold_time"
                      value={
                        formValues.sold_time === ""
                          ? ""
                          : formValues.sold_time ||
                            data?.attributes.correct_info.sold_time ||
                            setNowDate
                      }
                      onChange={handleInputChange}
                      type={"time"}
                      label="Время выезда"
                    />
                  </div>
                  <div className="w-full mb-4 flex flex-col gap-4 lg:flex-row lg:gap-1">
                    <div className="w-full">
                      <Input
                        name="diagnostics_date"
                        value={
                          formValues.diagnostics_date ||
                          data?.attributes.correct_info.diagnostics_date ||
                          setNowDate
                        }
                        onChange={handleInputChange}
                        type={"date"}
                        label="Дата диагностики"
                      />
                    </div>
                    <div className="w-full">
                      <Input
                        name="date_issue"
                        value={
                          formValues.date_issue ||
                          data?.attributes.correct_info.date_issue ||
                          setNowDate
                        }
                        onChange={handleInputChange}
                        type={"date"}
                        label="Дата выдачи"
                      />
                    </div>
                  </div>
                  <div className="w-full mb-4">
                    <Input
                      name="deadline"
                      value={
                        formValues.deadline ||
                        data?.attributes.correct_info.deadline ||
                        setNowDate
                      }
                      onChange={handleInputChange}
                      type={"date"}
                      label="Дедлайн"
                    />
                  </div>
                  <div className="w-full mb-4">
                    <Input
                      name="conclusion"
                      value={
                        formValues.conclusion === ""
                          ? ""
                          : formValues.conclusion ||
                            data?.attributes.correct_info.conclusion
                      }
                      onChange={handleInputChange}
                      label="Техническое заключение"
                    />
                  </div>
                  <div className="w-full mb-4">
                    <Input
                      name="estimation"
                      value={
                        formValues.estimation === ""
                          ? ""
                          : formValues.estimation ||
                            data?.attributes.correct_info.estimation
                      }
                      onChange={handleInputChange}
                      label="Общая стоимость работ"
                    />
                  </div>
                  <div className="w-full mb-4">
                    <Input
                      name="prepayment"
                      value={
                        formValues.prepayment === ""
                          ? ""
                          : formValues.prepayment ||
                            data?.attributes.correct_info.prepayment
                      }
                      onChange={handleInputChange}
                      label="Предоплата"
                    />
                  </div>
                  <div className="w-full mb-3">
                    <Input
                      name="equipment"
                      value={
                        formValues.equipment === ""
                          ? ""
                          : formValues.equipment ||
                            data?.attributes.correct_info.equipment
                      }
                      onChange={handleInputChange}
                      label="Комплектация принимаемого оборудования"
                      size="lg"
                    />
                  </div>
                  <div className="w-full mb-1">
                    <Textarea
                      name="work_done"
                      value={
                        formValues.work_done === ""
                          ? ""
                          : formValues.work_done ||
                            data?.attributes.correct_info.work_done
                      }
                      onChange={handleInputChange}
                      label="Выполненные работы"
                      size="lg"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-6 sm:col-full">
                <Button type="submit" ref={submitButtonRef}>
                  Сохранить
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default CorrectInfo;
