import React, { useEffect, useState } from "react";
import LeadItemRow from "../../components/Lead/LeadItemRow";

import axios from "axios";
import { getToken } from "../../helpers";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Radio,
} from "@material-tailwind/react";
import { toast } from "react-hot-toast";
import DownloadReport from "../../components/DownloadReport/DownloadReport";

const Dashboard = ({ isAdmin, userId, userBalance, filterRow, pageTitle }) => {
  const nowDate = new Date();
  const setNowDate = `${nowDate.getFullYear()}-${
    nowDate.getMonth() + 1 < 10
      ? "0" + (nowDate.getMonth() + 1)
      : nowDate.getMonth() + 1
  }-${nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate()}`;

  const userToken = getToken();
  const [allMasters, setAllMasters] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedMaster, setSelectedMaster] = useState(null);
  const [dateFrom, setDateFrom] = useState(setNowDate);
  const [dateTo, setDateTo] = useState(setNowDate);
  const [open, setOpen] = useState(false);
  const [leads, setLeads] = useState([]);
  const [error, setError] = useState("");
  /* const [profit, setProfit] = useState(0); */

  const sources = [
    {
      title: "spbgarant",
      letters: "G",
    },
    {
      title: "remtech",
      letters: "RT",
    },
    {
      title: "technorepair",
      letters: "TR",
    },
    {
      title: "allremont",
      letters: "AR",
    },
    {
      title: "Стационар",
      letters: "VO",
    },
  ];

  // Получаем всех мастеров
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/users`, {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      })
      .then((response) => {
        setAllMasters(response.data);
      })
      .catch((error) => {
        setError(error);
        toast.error("Ошибка загрузки мастеров");
        console.log(error);
      });
  }, [userToken]);

  // Запрос с фильтрами
  useEffect(() => {
    const queryParams = `/orders?pagination[pageSize]=2000&populate=*&sort=publishedAt:desc${filterRow}`;
    axios
      .get(process.env.REACT_APP_API_URL + queryParams, {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      })
      .then((response) => {
        setLeads(response.data.data);
        /* let sumProfit = 0;
        response.data?.data?.map((item) => {
          return (sumProfit += +item.attributes.order_profit);
        }); */
        //setProfit(sumProfit);
      })
      .catch((error) => {
        setError(error);
        toast.error("Ошибка загрузки лидов");
        console.log(error);
      });
  }, [isAdmin, userToken, userId, filterRow]);

  const handleOpen = () => setOpen(!open);

  const handleSourceChange = (event) => {
    setSelectedSource(event.target.value);
  };

  const handleMasterChange = (event) => {
    setSelectedMaster(event.target.value);
  };

  const applyFilters = () => {
    setOpen(false);

    const queryParams = `/orders?pagination[pageSize]=200&populate=*&filters[$and][0]${
      isAdmin
        ? "[order_status][$ne]=qwerty"
        : "[users_permissions_user][id]=" + userId
    }${
      dateFrom
        ? "&filters[$and][1][correct_info][sold_date][$gte]=" + dateFrom
        : ""
    }${
      dateTo ? "&filters[$and][2][correct_info][sold_date][$lte]=" + dateTo : ""
    }${
      selectedSource
        ? "&filters[$and][3][order_number][$contains]=" + selectedSource
        : ""
    }${
      selectedMaster
        ? "&filters[$and][4][users_permissions_user][last_name][$eq]=" +
          selectedMaster
        : ""
    }`;

    axios
      .get(process.env.REACT_APP_API_URL + queryParams, {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      })
      .then((response) => {
        setLeads(response.data.data);
        /*  let sumProfit = 0;
        response.data?.data?.map((item) => {
          return (sumProfit += +item.attributes.order_profit);
        });
        setProfit(sumProfit); */
      })
      .catch((error) => {
        setError(error);
        toast.error("Ошибка загрузки лидов после применения фильтров");
      });
  };

  const handleButtonClick = (buttonName) => {
    const currentDate = new Date();

    switch (buttonName) {
      case "3":
        currentDate.setDate(currentDate.getDate() - 3);
        break;
      case "7":
        currentDate.setDate(currentDate.getDate() - 7);
        break;
      case "14":
        currentDate.setDate(currentDate.getDate() - 14);
        break;
      case "30":
        currentDate.setDate(currentDate.getDate() - 30);
        break;
      default:
        break;
    }

    const formattedDate = currentDate.toISOString().split("T")[0];
    setDateFrom(formattedDate); // Установите нужное значение для dateTo, если оно отличается от dateFrom
  };

  // Поиск
  const searchLeads = (e) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/orders?populate=*&filters[$or][0][order_number][$contains]=${e.target.value}&filters[$or][1][client][phone][$contains]=${e.target.value}`,
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      )
      .then((response) => {
        setLeads(response.data.data);
      })
      .catch((error) => {
        setError(error);
        console.log(error);
      });
  };

  return (
    <>
      {error ? (
        error
      ) : (
        <>
          <div className="px-5 mx-auto py-10 md:w-4/5 w-11/12 md:basis-3/4 overscroll-contain max-h-screen">
            <div className="w-full h-full rounded ">
              <div className="w-full">
                <div className="px-4 py-0 md:py-7">
                  <div className="grid grid-cols-2 gap-3 md:flex-row md:flex md:justify-between md:items-center md:gap-10">
                    <p className="flex items-center text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
                      {pageTitle}
                    </p>
                    {/* <p
                      className={`ml-auto py-3 px-4 bg-green-100 rounded ${
                        !isAdmin && "hidden"
                      }`}
                    >
                      {userBalance || 0} руб.
                    </p> */}
                    <div className="py-3 px-4 col-span-2 flex items-center text-sm font-medium leading-none text-gray-600 bg-gray-200 hover:bg-gray-300 cursor-pointer rounded">
                      <Input
                        type="number"
                        label="Поиск"
                        onChange={searchLeads}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-4 md:flex-row md:justify-between md:items-center">
                  <div className="px-4 py-0 md:py-7 grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <Button onClick={handleOpen} variant="gradient">
                      Фильтрация
                    </Button>
                    <Dialog
                      open={open}
                      handler={handleOpen}
                      className="w-full max-w-[90%] lg:max-w-[40%] max-h-[95vh] overflow-y-auto"
                    >
                      <DialogHeader>
                        Выберите необходимые параметры
                      </DialogHeader>
                      <DialogBody divider>
                        <div className="mb-4">
                          <p className=" text-sm font-bold mb-4">
                            Выберите дату
                          </p>
                          <div className="grid sm:grid-cols-2 gap-4">
                            <div>
                              <p>От:</p>
                              <Input
                                name="dateFrom"
                                type="date"
                                value={dateFrom}
                                onChange={(e) => setDateFrom(e.target.value)}
                                defaultValue={"2023-01-01"}
                              />
                            </div>
                            <div>
                              <p>До:</p>
                              <Input
                                name="dateTo"
                                type="date"
                                value={dateTo}
                                onChange={(e) => setDateTo(e.target.value)}
                                defaultValue={"2023-12-31"}
                              />
                            </div>
                            <div className="grid grid-cols-2 sm:grid-cols-4 gap-3 sm:col-span-2">
                              <Button onClick={() => handleButtonClick("3")}>
                                3 дня
                              </Button>
                              <Button onClick={() => handleButtonClick("7")}>
                                7 дней
                              </Button>
                              <Button onClick={() => handleButtonClick("14")}>
                                14 дней
                              </Button>
                              <Button onClick={() => handleButtonClick("30")}>
                                30 дней
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="mb-4">
                          <p className=" text-sm font-bold mb-4">
                            Выберите источник
                          </p>
                          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-1">
                            {sources.map((item, index) => (
                              <div key={`${item.title}-${index}`}>
                                <Radio
                                  name="source"
                                  id={`${item.letters}`}
                                  label={item.title}
                                  value={item.letters}
                                  onChange={handleSourceChange}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="mb-4">
                          <p className=" text-sm font-bold mb-4">
                            Выберите мастера
                          </p>
                          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-1">
                            {allMasters.map((master, index) => (
                              <div
                                key={
                                  master.name +
                                  " " +
                                  master.last_name +
                                  "-" +
                                  index
                                }
                              >
                                <Radio
                                  name="master"
                                  id={`master-${index}`}
                                  label={master.name + " " + master.last_name}
                                  value={master.last_name}
                                  onChange={handleMasterChange}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </DialogBody>
                      <DialogFooter>
                        <Button
                          variant="text"
                          color="red"
                          onClick={handleOpen}
                          className="mr-1"
                        >
                          <span>Закрыть</span>
                        </Button>
                        <Button
                          variant="gradient"
                          color="green"
                          onClick={applyFilters}
                        >
                          <span>Применить</span>
                        </Button>
                      </DialogFooter>
                    </Dialog>
                  </div>

                  <DownloadReport leads={leads} pageTitle={pageTitle} />
                </div>

                <div className="bg-white">
                  <div className="mt-7 overflow-x-auto">
                    <table className="w-full whitespace-nowrap">
                      <thead className="hidden md:table-header-group">
                        <tr className="rounded-t-md border border-gray-100 border-b-0">
                          <td className="pl-5 py-3">
                            <p className="text-sm leading-none text-gray-600 ml-2">
                              Номер наряда
                            </p>
                          </td>
                          <td className="pl-5 py-3">
                            <p className="text-sm leading-none text-gray-600 ml-2">
                              Статус
                            </p>
                          </td>
                          <td className="pl-5 py-3 hidden">
                            <p className="text-sm leading-none text-gray-600 ml-2">
                              Место
                            </p>
                          </td>
                          <td className="pl-5 py-3">
                            <p className="text-sm leading-none text-gray-600 ml-2">
                              Дата выезда
                            </p>
                          </td>
                          <td className="pl-5 py-3">
                            <p className="text-sm leading-none text-gray-600 ml-2">
                              Об устройстве
                            </p>
                          </td>
                          <td className="pl-5 py-3">
                            <p className="text-sm leading-none text-gray-600 ml-2">
                              Мастер
                            </p>
                          </td>
                          <td className="pl-5 py-3">
                            <p className="text-sm leading-none text-gray-600 ml-2">
                              Данные клиента
                            </p>
                          </td>
                          {isAdmin && (
                            <td className="pl-5 py-3">
                              <p className="text-sm leading-none text-gray-600 ml-2">
                                Оплата
                              </p>
                            </td>
                          )}

                          <td className="pl-5 py-3"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {error ? (
                          <tr>
                            <td>error</td>
                          </tr>
                        ) : (
                          leads?.map((item) => (
                            <LeadItemRow
                              key={item.id}
                              id={item.id}
                              item={item}
                              isAdmin={isAdmin}
                            />
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
