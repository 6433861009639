import axios from "axios";
import React, { useEffect, useState } from "react";
import { getToken } from "../../../helpers";
import { toast } from "react-hot-toast";
import { Alert, Button, Input } from "@material-tailwind/react";
import TableRow from "./TableRow";

const UploadDocuments = ({ id, data, filesList, isAdmin }) => {
  const userToken = getToken();
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFiles(filesList);
  }, [filesList]);

  let currentUploads = [];

  data?.attributes.order_files.data?.forEach((item) => {
    currentUploads.push(item.id);
  });

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("files", file);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      );
      currentUploads.push(res.data[0].id);
      const responseFiles = await axios.put(
        `${process.env.REACT_APP_API_URL}/orders/${id}?populate=order_files`,
        {
          data: {
            order_files: currentUploads,
          },
        },
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      );
      setFiles(responseFiles.data.data.attributes.order_files.data);
      toast.success("Файлы успешно загружены");
    } catch (error) {
      toast.error("Ошибка при загрузке файлов");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = (e) => {
    const updatedChatArray = files.filter(
      (item) => +item.id !== +e.target.dataset.uploadId
    );
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/orders/${id}?populate=order_files`,
        {
          data: {
            order_files: updatedChatArray,
          },
        },
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      )
      .then((response) => {
        setFiles(response.data?.data?.attributes.order_files?.data);
        toast.success("Файл удален");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Ошибка удаления файла");
      });
  };

  return (
    <div className="mb-8 p-4 border rounded">
      <h3 className="mb-4 text-xl font-bold">Загрузка документов/чеков</h3>
      <form onSubmit={handleSubmit} className="mb-5">
        <div className="mb-5">
          <Input
            type="file"
            onChange={handleFileUpload}
            label="Загрузите 1 файл"
            accept="*/*"
          />
        </div>
        <Button type="submit" disabled={loading}>
          {loading ? "Загрузка…" : "Загрузить файл"}
        </Button>
      </form>
      <div className="grid grid-cols-6 gap-6 mt-8">
        <div className="bg-white mb-6 col-span-6">
          <h3 className="mb-4 text-xl font-bold">Список загруженных файлов</h3>
          <div className="rounded-lg border">
            {files !== null ? (
              <table className="min-w-full text-left text-sm font-light">
                <thead className="border-b font-medium dark:border-neutral-500">
                  <tr>
                    <th scope="col" className="px-6 py-4">
                      #
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Название файла
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Размер
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Скачать файл
                    </th>
                    {!!isAdmin && (
                      <th scope="col" className="px-6 py-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {files?.map((item, index) => (
                    <TableRow
                      key={item.id}
                      id={item.id}
                      filename={item.attributes.name}
                      size={item.attributes.size}
                      link={
                        process.env.REACT_APP_UPLOAD_URL + item.attributes.url
                      }
                      index={index}
                      onClick={onDelete}
                    />
                  ))}
                </tbody>
              </table>
            ) : (
              <div>
                <Alert color="blue" className="w-full">
                  Пока нет загруженных файлов
                </Alert>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocuments;
