import { Button, Input } from "@material-tailwind/react";
import axios from "axios";
import React, { useState } from "react";

const Debiting = ({ master, userToken }) => {
  console.log("master: ", master);
  const allDebits = master.minus_money;
  const [debit, setDebit] = useState(0);
  const [comment, setComment] = useState("");

  const handleSubmit = async (e) => {
    allDebits.push({
      amount: e.target.debit.value,
      date: new Date(),
      comment: e.target.debit.comment,
      user: `${master.name} ${master.last_name}`,
    });
    console.log("allDebits: ", allDebits);
    e.preventDefault();


    axios
      .put(
        `${process.env.REACT_APP_API_URL}/users/${master.id}?populate=minus_money`,
        {
          minus_money: allDebits,
        },
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      )
      .then((response) => {
        console.log("response: ", response);
      })
      .catch((error) => {
        console.log(error);
      });

    /*  try {
      allDebits.push(res.data[0].id);
      const responseFiles = await axios.put(
        `${process.env.REACT_APP_API_URL}/orders/${id}?populate=order_files`,
        {
          data: {
            order_files: currentUploads,
          },
        },
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      );
      setFiles(responseFiles.data.data.attributes.order_files.data);
      toast.success("Файлы успешно загружены");
    } catch (error) {
      toast.error("Ошибка при загрузке файлов");
      console.error(error);
    } */
  };

  return (
    <div className="mt-8">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="mb-4">
          <Input
            name="debit"
            type={"number"}
            value={debit}
            onChange={(e) => setDebit(e.value)}
            label="Cумма списания"
          />
        </div>
        <div className="mb-4">
          <Input
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.value)}
            label="Комментарий"
          />
        </div>
        <div>
          <Button type="submit">Списать</Button>
        </div>
      </form>
    </div>
  );
};

export default Debiting;
