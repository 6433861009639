import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import axios from "axios";

import { getToken } from "./helpers";

import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Clients from "./pages/Clients/Clients";

import Sidebar from "./components/Sidebar/Sidebar";
import CreateLead from "./components/CreateLead/CreateLead";
import Lead from "./components/Lead/Lead";
import ToastProvider from "./providers/Toaster";
import { Masters } from "./pages/Masters/Masters";
import Master from "./pages/Master/Master";

function App() {
  const userToken = getToken();
  const [isLogged, setIsLogged] = useState(false);
  const [userId, setUserid] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userBalance, setUserBalance] = useState(0);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/me?populate=role`, {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      })
      .then((response) => {
        setUserBalance(response.data.balance);
        setUserName(response.data.name + " " + response.data.last_name);

        response.data?.role?.type === "admin"
          ? setIsAdmin(true)
          : setIsAdmin(false);

        setIsLogged(true);
        setUserid(response.data.id);
      })
      .catch((error) => {
        setIsLogged(false);
        console.log(error);
      });
  }, [isLogged, userToken]);

  return (
    <>
      <ToastProvider />
      {isLogged ? (
        <div className="flex flex-no-wrap min-h-screen">
          <Sidebar isAdmin={isAdmin} />
          <div className="w-80 min-w-[320px] hidden lg:block"></div>
          <Routes>
            <Route
              path="/"
              element={
                <Dashboard
                  isAdmin={isAdmin}
                  userId={userId}
                  userBalance={userBalance}
                  pageTitle="Все наряды"
                  filterRow={`${
                    !isAdmin
                      ? `&filters[$and][3][users_permissions_user][id]=${userId}`
                      : ""
                  }`}
                />
              }
            />
            <Route
              path="/dashboard"
              element={
                <Dashboard
                  isAdmin={isAdmin}
                  userId={userId}
                  userBalance={userBalance}
                  pageTitle="Все наряды"
                  filterRow={`${
                    !isAdmin
                      ? `&filters[$and][3][users_permissions_user][id]=${userId}`
                      : ""
                  }`}
                />
              }
            />
            <Route
              path="/new"
              element={
                <Dashboard
                  isAdmin={isAdmin}
                  userId={userId}
                  userBalance={userBalance}
                  pageTitle="Новые заявки"
                  filterRow={`&filters[$and][0][order_status][$eq]=Новая${
                    !isAdmin
                      ? `&filters[$and][1][users_permissions_user][id]=${userId}`
                      : ""
                  }`}
                />
              }
            />
            <Route
              path="/coordination"
              element={
                <Dashboard
                  isAdmin={isAdmin}
                  userId={userId}
                  userBalance={userBalance}
                  pageTitle="Согласование"
                  filterRow={`&filters[$and][0][order_status][$eq]=Согласовать${
                    !isAdmin
                      ? `&filters[$and][1][users_permissions_user][id]=${userId}`
                      : ""
                  }`}
                />
              }
            />
            <Route
              path="/send-an-engineer"
              element={
                <Dashboard
                  isAdmin={isAdmin}
                  userId={userId}
                  userBalance={userBalance}
                  pageTitle="Отправить инженера"
                  filterRow={`&filters[$and][0][order_status][$eq]=Отправить инженера${
                    !isAdmin
                      ? `&filters[$and][1][users_permissions_user][id]=${userId}`
                      : ""
                  }`}
                />
              }
            />

            <Route
              path="/for-check"
              element={
                <Dashboard
                  isAdmin={isAdmin}
                  userId={userId}
                  userBalance={userBalance}
                  pageTitle="Заказы на проверку"
                  filterRow={`&filters[$and][0][order_status][$eq]=Проверить${
                    !isAdmin
                      ? `&filters[$and][1][users_permissions_user][id]=${userId}`
                      : ""
                  }`}
                />
              }
            />
            <Route
              path="/archive"
              element={
                <Dashboard
                  isAdmin={isAdmin}
                  userId={userId}
                  userBalance={userBalance}
                  pageTitle="Архив заказов"
                  filterRow={`&filters[$or][0][order_status][$eq]=Отказ&filters[$or][1][order_status][$eq]=Выдан${
                    !isAdmin
                      ? `&filters[$and][0][users_permissions_user][id]=${userId}`
                      : ""
                  }`}
                />
              }
            />
            <Route
              path="/refusal"
              element={
                <Dashboard
                  isAdmin={isAdmin}
                  userId={userId}
                  userBalance={userBalance}
                  pageTitle="Отказные"
                  filterRow={`&filters[$and][0][order_status][$eq]=Отказ${
                    !isAdmin
                      ? `&filters[$and][1][users_permissions_user][id]=${userId}`
                      : ""
                  }`}
                />
              }
            />
            <Route path="/clients" element={<Clients isAdmin={isAdmin} />} />
            <Route path="/createlead" element={<CreateLead />} />
            <Route
              path="/lead/:id"
              element={
                <Lead isAdmin={isAdmin} userId={userId} userName={userName} />
              }
            />
            <Route path="/masters" element={<Masters isAdmin={isAdmin} />} />
            <Route
              path="/masters/:id"
              element={
                <Master isAdmin={isAdmin} userId={userId} userName={userName} />
              }
            />
          </Routes>
        </div>
      ) : (
        <Login />
      )}
    </>
  );
}

export default App;
