import React, { useState } from "react";
import {
  Document,
  Page,
  Text,
  Font,
  StyleSheet,
  View,
  Image,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
} from "@material-tailwind/react";

// Загрузка кастомного шрифта
Font.register({
  family: "CustomFont",
  src: "/fonts/Roboto-Regular.ttf", // Замените на фактический URL или путь к файлу шрифта
});

const ContractDocument = ({ lead, hiddenSign }) => {
  const masterFio = lead.attributes.users_permissions_user.data?.attributes
    ?.name
    ? lead.attributes.users_permissions_user.data?.attributes?.name +
      " " +
      lead.attributes.users_permissions_user.data?.attributes?.last_name
    : null;

  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#fff",
      fontFamily: "CustomFont",
      fontSize: 7,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    header: {
      flexDirection: "row",
      margin: 10,
      padding: 10,
      justifyContent: "space-between",
    },
    logo: {
      width: 100,
      objectFit: "contain",
    },
    seal: {
      width: 200,
      height: 200,
      objectFit: "contain",
      position: "absolute",
      bottom: -75,
      left: "50%",
    },
    sign: {
      width: 200,
      height: 200,
      objectFit: "contain",
      position: "absolute",
      bottom: -78,
      left: "72%",
    },
    paragraph: {
      marginBottom: 5,
    },
    table: {
      flexDirection: "column",
      marginLeft: 30,
      marginRight: 30,
      marginBottom: 20,
    },
    tableRow: {
      flexDirection: "row",
      border: "1px solid #000",
      borderBottom: 0,
    },
    footer: {
      margin: 10,
      marginTop: 0,
      padding: 10,
      flexGrow: 1,
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={{ flexBasis: 200 }}>
            <Text style={{ marginBottom: 5 }}>Адрес: г.Санкт-Петербург</Text>
            <Text style={{ marginBottom: 5 }}>ул Фрунзе д.6</Text>
            <Text style={{ marginBottom: 5 }}>Телефон; 8 (812)-220-70-70</Text>
          </View>
          <Image src={"/images/logo.png"} style={styles.logo} />
          <View style={{ flexBasis: 200, textAlign: "right" }}>
            <Text style={{ marginBottom: 5 }}>ООО “Гарант”</Text>
            <Text style={{ marginBottom: 5 }}>ИНН 4705097126</Text>
            <Text style={{ marginBottom: 5 }}>spbgarant.ru</Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text
              style={{
                flexBasis: 300,
                padding: 3,
                borderRight: "1px solid #000",
              }}
            >
              Ф.И.О Заказчика: {lead.attributes.client?.data?.attributes?.name}
            </Text>
            <Text
              style={{
                flexBasis: 300,
                padding: 3,
              }}
            >
              Заказ принял: {masterFio}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text
              style={{
                flexBasis: 300,
                padding: 3,
                borderRight: "1px solid #000",
              }}
            >
              Адрес:{" "}
              {lead.attributes.client.data.attributes.address || "Стационар"}
            </Text>
            <Text style={{ flexBasis: 300, padding: 3 }}>
              Заявленный дефект: {lead.attributes.correct_info.defect}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text
              style={{
                flexBasis: 300,
                padding: 3,
                borderRight: "1px solid #000",
              }}
            >
              Тип техники: {lead.attributes.correct_info.device}
            </Text>
            <Text
              style={{
                flexBasis: 300,
                padding: 3,
              }}
            >
              Дата приема: {lead.attributes.correct_info.sold_date}
            </Text>
          </View>

          <View style={styles.tableRow}>
            <Text
              style={{
                flexBasis: 300,
                padding: 3,
                borderRight: "1px solid #000",
              }}
            >
              Производитель/модель: {lead.attributes.correct_info.brand}/
              {lead.attributes.correct_info.model}
            </Text>
            <Text
              style={{
                flexBasis: 300,
                padding: 3,
              }}
            >
              Внешний вид/комплектация: {lead.attributes.correct_info.equipment}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text
              style={{
                flexBasis: 300,
                padding: 3,
                borderRight: "1px solid #000",
              }}
            >
              Номер телефона: {lead.attributes.client.data.attributes?.phone}
            </Text>
            <Text
              style={{
                flexBasis: 300,
                padding: 3,
              }}
            >
              Тип заказа: {lead.attributes.correct_info.type_of_repair}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text
              style={{
                flexBasis: "100%",
                padding: 3,
                borderBottom: "1px solid #000",
              }}
            >
              Предоплата: {lead.attributes.correct_info.prepayment}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text
            style={{
              textAlign: "center",
              marginBottom: "20",
              fontWeight: 700,
              fontSize: 12,
            }}
          >
            Договор № {lead?.attributes?.order_number}
          </Text>
          <Text style={styles.paragraph}>
            1. Заказчик сдал, а Исполнитель принял технику в состоянии и
            комплектности, указанной выше, для проведения диагностики с целью
            определения причин возникновения неисправностей, способов, сроков и
            стоимости их устранения. Стоимость диагностики составляет 500 руб в
            случае отказа от ремонта. Срок проведения диагностики не может
            превышать 10 (десять) рабочих дней. По окончании диагностики
            Исполнитель уведомляет Заказчика об окончательной стоимости,
            приблизительных сроках технического обслуживания и иных
            особенностях, связанных с устранением неисправности в технике
            Заказчика. В окончательную стоимость технического обслуживания
            включена, стоимость замененных запасных частей, стоимость работ
            технического специалиста Исполнителя по устранению неисправности.
            Подтверждая сообщенную Исполнителем полную стоимость технического
            обслуживания, Заказчик выражает свое согласие на возмездное
            устранение неисправности согласно условиям настоящего Договора.
            Выражение воли Заказчика может быть подтверждено записью телефонного
            разговора, смс-сообщением, полученным с телефонного номера
            Заказчика, записью с камер видеонаблюдения, расположенных в
            помещении Исполнителя при отказе Заказчика от дальнейшего
            обслуживания техники (к примеру, в случае несогласия с окончательной
            стоимостью, сроком обслуживания или отсутствия оригинальных деталей
            у Исполнителя), сумма, уплаченная за диагностику, не возвращается.
          </Text>
          <Text style={styles.paragraph}>
            2. Заказчик выражает свое согласие с тем, что в процессе
            технического обслуживания, техника может быть модернизирована, в том
            числе добавлен новый функционал, как путем обновления программного
            обеспечения (прошивки), так и путем замены электронных компонентов.
          </Text>
          <Text style={styles.paragraph}>
            3. Заказчик выражает свое согласие с тем, что при замене корпусных
            частей техники, они могут иметь незначительное отличие в оттенке от
            изначально сданных на техническое обслуживание.
          </Text>
          <Text style={styles.paragraph}>
            4. Исполнитель вправе отказать Заказчику в диагностике и последующем
            техническом обслуживании техники в связи с истечением срока ее
            службы, отсутствием серийного номера, сильной загрязненности и иных
            обоснованных причинах, озвученных Заказчику при принятии техники.
          </Text>
          <Text style={styles.paragraph}>
            5. При не гарантийном техническом обслуживании сроки устранения
            неисправностей могут зависеть от количества и сложности
            неисправностей, а также от наличия необходимых деталей и от сроков
            поставки деталей Изготовителем. В любом случае окончательный срок
            технического обслуживания не может превышать 4 (четыре) месяца с
            момента сдачи Заказчиком техники на техническое обслуживание.
            возникают последствия, указанные в п. 9 настоящего Договора.
          </Text>
          <Text style={styles.paragraph}>
            6. Исполнитель устанавливает гарантийный срок на совершенное
            техническое обслуживание. Гарантийный срок на произведенные работы
            техническим специалистом и замененные детали (запасные части)
            составляет - 45 календарных дней при условии соблюдения правил
            эксплуатации. Если техника до передачи ее на техническое
            обслуживание было подвержено воздействию жидкости или платы имели
            механические или электрические повреждения гарантийный срок
            составляет 45 календарный дней
          </Text>
          <Text style={styles.paragraph}>
            7. Исполнитель не несет ответственность за принадлежности,
            комплектности и аксессуары, не указанные в настоящем документе при
            сдаче техники на гарантийное сервисное обслуживание, а также за
            повреждения аудио- и видеокассет, компакт- и мини-дисков, карт
            памяти, связанные с их извлечением из неисправной техники.
            Исполнитель не несет ответственности за сохранность информации на
            цифровых, медиа и других носителях переданной на сервисное
            обслуживание техники.
          </Text>
          <Text style={styles.paragraph}>
            8. Заказчик самостоятельно отслеживает окончание срока технического
            обслуживания по телефону +7 (812) 220-70-70. Если диагностика или
            техническое обслуживание завершены ранее срока, указанного в п. 1 и
            п. 5 настоящего договора или возникла необходимость продления срока,
            Заказчик может быть извещен по телефону, указанному в настоящем
            договоре или смс-сообщением. В случае неясности информации или
            обнаружения пропущенного звонка с указанного номера телефона
            Заказчик обязуется перезвонить на номер +7 (812) 220-70-70 с целью
            уточнения информации о сданной технике. С момента окончания срока
            технического обслуживания, указанного в п. 5 настоящего договора или
            получением информации об ее окончании указанными в настоящем пункте
            способами, Заказчик считается надлежаще извещенным о завершении
            технического обслуживания техники и готовности Исполнителя
            возвратить исправную технику. Окончание срока технического
            обслуживания обязывает Заказчика явиться по месту передачи техники
            Исполнителю. Исполнитель вправе, во время нахождения у него техники
            Заказчика, осуществлять телефонные звонки последнему с 10:00 до
            20:00 без выходных с целью уточнения информации, имеющей значение
            для устранения недостатка.
          </Text>
          <Text style={styles.paragraph}>
            9. По истечении 45 дней со дня окончания технического обслуживания
            техники Заказчика, Исполнитель направляет технику на склад
            временного хранения. Подписав настоящее соглашение, Заказчик
            подтверждает, что он уведомлен о том, что при явке по истечении 45
            дней после извещения к месту передачи техники Исполнителю, техника
            не может быть выдана ему незамедлительно, а также согласен
            возместить Исполнителю расходы, понесенные им в связи с
            транспортировкой и хранением товара на складе временного хранения.
            По истечении 1 (одного) месяца хранения техники она подлежит
            утилизации.
          </Text>
          <Text style={styles.paragraph}>
            10. Заказчик самостоятельно несет риски, связанные с предоставлением
            им недостоверных сведений Исполнителю, в том числе об адресе, номере
            телефона, и др. В случае утери Заказчиком своего экземпляра
            Договора, выдача техники производится при предъявлении паспорта
            лицом, передавшим технику Исполнителю на сервисное обслуживание. При
            несовпадении паспортных данных и данных, указанных Заказчиком в
            настоящем Договоре техника выдаче, не подлежит.
          </Text>
          <Text style={styles.paragraph}>
            11. Заказчик выражает свое согласие на обработку и использование
            средств автоматизации или без таковых своих персональных данных,
            указанных в Договоре и в иных представленных документах, в целях
            исполнения настоящего Договора. С указанными целями в отношении
            персональных данных будут совершаться такие действия, как сбор,
            систематизация, накопление, хранение, уточнение (обновление,
            изменение).
          </Text>
          <Text style={styles.paragraph}>
            12. Заказчик вправе в любое время до истечения срока, установленного
            п. 5 Договора, заявить Исполнителю об отказе от исполнения
            настоящего Договора, при условии оплаты Исполнителю фактически
            понесенных им расходов, связанных с исполнением обязательств по
            Данному договору.
          </Text>
          <Text style={styles.paragraph}>
            13. В связи с возникновением ситуации, при которой Исполнитель
            пришел к выводу о невозможности устранения неисправности в технике
            Заказчика, Исполнитель вправе в любое время до истечения срока,
            установленного п. 5 Договора, уведомить Заказчика об отказе от
            исполнения настоящего Договора в одностороннем порядке. С момента
            получения такого отказа и не явки Заказчика, возникают последствия,
            указанные в п. 9 настоящего Договора.
          </Text>
        </View>
        <View style={styles.footer}>
          <Text style={{ marginBottom: 20 }}>
            С условиями сервисного обслуживание ознакомлен:
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <Text>Заказчик:____________________/____________</Text>
            {!hiddenSign && (
              <>
                <Image src={"/images/seal.png"} style={styles.seal} />
                <Image src={"/images/sign.png"} style={styles.sign} />
              </>
            )}
            <Text>Исполнитель:____________________/____________</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const Contract = ({ lead, hiddenSign, isPreview }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      {isPreview ? (
        <>
          <Button onClick={handleOpen}>Договор (физ лицо)</Button>
          <Dialog
            open={open}
            size={"sm"}
            handler={handleOpen}
            className="w-full !max-w-[90%] lg:!max-w-[40%]"
          >
            <DialogHeader>{`Договор ${lead?.attributes.order_number}.pdf`}</DialogHeader>
            <DialogBody divider className="w-full">
              <PDFViewer width="100%" height="600">
                <ContractDocument lead={lead} hiddenSign={hiddenSign} />
              </PDFViewer>
            </DialogBody>
          </Dialog>
        </>
      ) : (
        <Button className="!p-0 h-10">
          <PDFDownloadLink
            document={<ContractDocument lead={lead} hiddenSign={hiddenSign} />}
            fileName={`Договор ${lead?.attributes.order_number}.pdf`}
            className="flex justify-center items-center h-full w-full"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Загрузка документа..." : "Договор (физ лицо)"
            }
          </PDFDownloadLink>
        </Button>
      )}
    </>
  );
};

export default Contract;
