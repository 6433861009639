import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import axios from "axios";
import { getToken } from "../../helpers";
import MasterLeads from "../../components/Lead/Master/MasterLeads";
import Calculations from "../../components/Lead/Master/Calculations";

const Master = ({ isAdmin }) => {
  const userToken = getToken();
  const userId = parseInt(useParams().id);
  const [leads, setLeads] = useState([]);
  const [master, setMaster] = useState([]);
  const [moneyRows, setMoneyRows] = useState([]);
  const [consumptionRows, setConsumptionRows] = useState([]);
  const [balance, setBalance] = useState(0);

  // Получаем лиды
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/orders?pagination[pageSize]=2000&populate=*&sort=publishedAt:desc&filters[$and][0][users_permissions_user][id]=${userId}`,
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      )
      .then((response) => {
        setLeads(response.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userToken, userId]);

  useEffect(() => {
    const rows = [];
    leads.forEach((item) => {
      if (item.attributes?.income !== null) {
        rows.push(item.attributes.income);
      }
    });
    setMoneyRows(rows);
  }, [leads]);

  // Получаем данные мастера
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/${userId}?populate=*`, {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      })
      .then((response) => {
        setMaster(response.data);
        setConsumptionRows(response.data.minus_money);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userToken, userId]);

  useEffect(() => {
    let income = 0;
    let consumption = 0;
    leads?.forEach((item) => {
      if (item.attributes.income?.master_cost) {
        income += +item.attributes.income?.master_cost;
      }
    });
    master.minus_money?.forEach((item) => (consumption += +item.amount));
    setBalance(income - consumption);
  }, [leads, master]);

  const tabs = [
    {
      label: "Лиды",
      onlyAdmin: false,
      value: "correct",
      component: <MasterLeads leads={leads} isAdmin={isAdmin} />,
    },
    {
      label: "Расчеты",
      onlyAdmin: false,
      value: "client",
      component: (
        <Calculations
          userId={userId}
          isAdmin={isAdmin}
          userToken={userToken}
          moneyRows={moneyRows}
          consumptionRows={consumptionRows}
          master={master}
        />
      ),
    },
  ];

  return (
    <div className="container mx-auto py-10 md:w-4/5 w-11/12 overflow-hidden overflow-y-scroll max-h-screen md:basis-3/4 md:px-5">
      <div className="flex flex-col justify-between gap-3 col-span-full mb-4 md:flex-row">
        <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
          {`${master.last_name} ${master.name} ${master.middle_name || ""}`}
        </h1>
        <div className="p-5 rounded bg-gray-200 text-lg font-semibold">
          Баланс: {balance} руб.
        </div>
      </div>

      <Tabs value="correct">
        <TabsHeader className="flex gap-4 overflow-y-auto">
          {tabs?.map(({ label, value, index, onlyAdmin }) =>
            isAdmin || !onlyAdmin ? (
              <Tab
                key={value}
                value={value}
                className="text-gray-600 whitespace-nowrap"
                active={index === 0 ? "active" : ""}
              >
                {label}
              </Tab>
            ) : (
              ""
            )
          )}
        </TabsHeader>
        <TabsBody>
          {tabs?.map(({ value, component, onlyAdmin }) =>
            isAdmin || !onlyAdmin ? (
              <TabPanel key={value} value={value} className="p-0">
                {component}
              </TabPanel>
            ) : (
              ""
            )
          )}
        </TabsBody>
      </Tabs>
    </div>
  );
};

export default Master;
